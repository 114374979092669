import React from "react";

function App() {
  window.location.replace("https://www.nytimes.com/games/wordle/index.html");
  return (
    <div>
      {/* <iframe
        title="Wordle"
        src="https://www.nytimes.com/games/wordle/index.html"
        style={{
          position: "fixed",
          top: "0",
          left: "0",
          bottom: "0",
          right: "0",
          width: "100%",
          height: "100%",
          border: "none",
          margin: "0",
          padding: "0",
          overflow: "hidden",
          zIndex: "999999",
        }}
      >
        Your browser doesn't support iframes
      </iframe> */}
    </div>
  );
}

export default App;
